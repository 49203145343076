

























type RouteItem = {
  routeName: string;
  label: string;
};

export default {
  name: 'SuperAdminProvidersView',
  data(): { childRoutes: RouteItem[] } {
    return {
      childRoutes: [
        {
          routeName: 'providers-proxy-integration',
          label: 'Proxy Integration'
        },
        {
          routeName: 'providers-accounts',
          label: 'Accounts'
        }
      ]
    };
  }
};
